<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="approvingofficers.data"
      :items-per-page="30"
			dense
			hide-default-footer
			:search="search"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
			<v-toolbar-title>Billing Approving Officers</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-text-field
			v-model="search"
			append-icon="search"
			label="Search"
			single-line
			hide-details
			></v-text-field> 
			<vue-excel-xlsx
				:data="approvingofficers.data"
				:columns="columnHeaders"
				:file-name="module_name"
				>
				<v-btn icon alt class="mt-2" :color="approvingofficers.data.length=== 0 ? 'gray' : 'primary'" :disabled="approvingofficers.data.length===0"><v-icon>mdi-arrow-down-bold-circle</v-icon> </v-btn>
			</vue-excel-xlsx>
        </v-toolbar>
      </template>
			<template v-slot:item.min_amount="{ item }">
				<span>{{formatAmount(item.min_amount)}}</span>
			</template>
			<template v-slot:item.max_amount="{ item }">
				<span>{{formatAmount(item.max_amount)}}</span>
			</template>
      <template v-slot:item.action="{ item }">
        <v-icon
			color="grey"
			class="mr-2"
			small
			v-if="role_access < 3"
		>
			mdi-pencil-off
		</v-icon>
		<v-icon
			color="primary"
			v-else
			class="mr-2"
			small
			@click="editItem(item)"
		>
			edit
		</v-icon>
			<v-icon
			color="grey"
			class="mr-2"
			small
			v-if="role_access < 4"
		>
			mdi-delete-off
		</v-icon>
		<v-icon
			small
			v-else
			color="red"
			@click="deleteItem(item)"
		>
			delete
		</v-icon>
      </template>
			<template v-slot:body.append>
					<tr>
						<td colspan="8">
							<v-layout>
								<v-spacer></v-spacer>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											:disabled="parseInt(approvingofficers.current_page)==1"
											@click="changePrevData"
											class="ma-2"
											text
											icon
										>
											<v-icon :disabled="parseInt(approvingofficers.current_page)==1">mdi-chevron-left</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
								<v-flex md2 style="padding-top:15px;text-align: center">
									<p class="font-weight-light text--primary body-2">
										Page {{approvingofficers.current_page}} ( {{approvingofficers.from}} - {{approvingofficers.to}} )
									</p>
								</v-flex>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											@click="changeNextData"
											class="ma-2"
											:disabled="!approvingofficers.next_page_url"
											text
											icon
										>
											<v-icon :disabled="!approvingofficers.next_page_url">mdi-chevron-right</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
							</v-layout>
						</td>
					</tr>
			</template>
    </v-data-table>
    <create-approvingofficer v-if="role_access > 1" />
  </div>
</template>

<script>
import createApprovingOfficer from './createApprovingOfficer.vue';
import { mapGetters } from 'vuex';
import numeral from 'numeral';

export default {
	data(){
		return{
			module_name: 'Approving Officers',
			role_access: 1,
			search: ''
		};
	},
	components: {
		'create-approvingofficer': createApprovingOfficer
	},
	computed: {
		...mapGetters('billingapprovingofficers', [
			'approvingofficers',
			'headers',
			'columnHeaders',
			'editedItem',
			'defaultItem',
			'dialog',
			'editedIndex',
			'isLoading',
			'valid',
			'currency'
		]),
		...mapGetters({
			currUser: 'auth/currUser'
		}),
	},
	mounted () {
		this.$store.dispatch('billingapprovingofficers/getApprovingOfficers');
		this.$store.dispatch('user/getUsers');
		this.$store.dispatch('currency/getCurrencies');

		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	methods: {
		async editItem (item) {
			this.$store.dispatch('billingapprovingofficers/setEditedIndex', this.approvingofficers.data.indexOf(item));
			// this.$store.dispatch('billingapprovingofficers/setEditedItems', Object.assign({}, item));
			await this.$store.dispatch('billingapprovingofficers/getDetails', item.id);
			this.$store.dispatch('billingapprovingofficers/setDialog', true);
			this.$store.dispatch('billingapprovingofficers/setValid', true);
			this.$store.dispatch('billingapprovingofficers/setformTitle', 'Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: `Remove ${item.approver_name} from the list?`,
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('billingapprovingofficers/setEditedIndex', this.approvingofficers.data.indexOf(item));
					this.$store.dispatch('billingapprovingofficers/deleteApprovingOfficer', item);
				}
			});
		},
		formatAmount(amount){
			return numeral(amount).format('0,0.00');
		},

		changeNextData() {
			var nextPage = this.approvingofficers.current_page + 1;
			this.$store.dispatch('billingapprovingofficers/getDataPages', nextPage);
		},

		changePrevData() {
			var prevPage = this.approvingofficers.current_page - 1;
			this.$store.dispatch('billingapprovingofficers/getDataPages', prevPage);
		}
	}
};
</script>