<template>
	<v-dialog v-model="dialog" persistent max-width="60%" scrollable>
		<template v-slot:activator="{ on }">
			<app-add-button v-on="on" v-bind:module="'billingapprovingofficers'"></app-add-button>
		</template>
		<v-form ref="form" v-model="isvalid" lazy-validation>
			<v-card tile>
				<v-toolbar flat dark color="primary">
					<v-toolbar-title>{{ formTitle }}</v-toolbar-title>
					<div class="flex-grow-1"></div>
					<v-btn icon dark @click="close">
						<v-icon>close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<v-container grid-list-md>
						<v-layout wrap>
							<v-flex xs12 sm6 md4>
								<v-select
									v-model="editedItem.user_id"
									:items="userData"
									data-vv-name="users"
									item-text="user_id"
									item-value="id"
									label="User ID"
									@change="displayFullname"
									:rules="rules"
									required>
								</v-select>
							</v-flex>

							<v-flex xs12 sm6 md4>
								<v-select
									v-model="editedItem.currency_id"
									:items="currencies"
									data-vv-name="currencies"
									item-text="cur_desc"
									item-value="id"
									label="Currency"
									required
								></v-select>
							</v-flex>

							<v-flex xs12 sm6 md4>
								<v-text-field v-model="editedItem.approver_name" label="Approver Name" readonly></v-text-field>
							</v-flex>
							<v-flex xs12 sm6 md4>
								<v-select
									v-model="editedItem.level"
									:items="levels"
									data-vv-name="level"
									item-text="name"
									item-value="id"
									label="Level"
									:rules="rules"
									required
								></v-select>
							</v-flex>
							<v-flex xs12 sm6 md4>
								<v-text-field v-model="editedItem.min_amount" label="Minimum Amount" ></v-text-field>
							</v-flex>

							<v-flex xs12 sm6 md4>
								<v-text-field v-model="editedItem.max_amount" label="Maximum Amount" ></v-text-field>
							</v-flex>

						</v-layout>
					</v-container>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
						<v-btn color="blue darken-1" dark @click="save">Save</v-btn>
						<v-btn color="red darken-1"  text @click="close">Cancel</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';

export default {
	components: {
		'app-add-button': AppAddButtonVue
	},
	data: () => ({
		isvalid: false,
		rules: [v => !!v || 'Required'],
		userData: [],
		nextPage: '',
		levels: [
			{id: 1, name: 'Immediate Supervisor'},
			{id: 2, name: 'Department Head'},
			{id: 3, name: 'Accounting Assistant'
			}]
	}),

	computed: {
		...mapGetters('billingapprovingofficers', [
			'approvingofficers',
			'editedItem',
			'defaultItem',
			'dialog',
			'editedIndex',
			'formTitle',
			'isLoading',
			'valid'
		]),
		...mapGetters({
			users: 'user/users',
			approvinglevels: 'approvinglevel/approvinglevels',
			currencies: 'currency/currencies',
			currUser: 'auth/currUser'
		})
	},
	watch: {
		users: {
			handler(){
				this.getData();
			}
		}
	},
	methods: {
		async getData(){
			this.userData = this.users.data;
			this.nextPage = this.users.next_page_url;
			let page = 2;

			while (!this.users.data){
				await this.$store.dispatch('users/getData', page).then(response => {
					this.nextPage = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.userData.push(details);
					});
				});
				page++;
			}
		},
		close() {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('billingapprovingofficers/setValid', true);
				this.$store.dispatch('billingapprovingofficers/setDialog', false);
				this.$store.dispatch('billingapprovingofficers/setEditedIndex', -1);
				//this.isvalid = false;
			}, 2000);
		},

		async save() {
			let {id, user_id, currency_id, min_amount, max_amount,level} = this.editedItem;
			let edit_by = this.currUser.id;
			let index = this.editedIndex;
			let users = this.users.data;

			this.$store.dispatch('billingapprovingofficers/setValid', this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			if (this.valid) {
				this.$store.dispatch('billingapprovingofficers/setisLoading', true);

				if (index > -1) {
					await this.$store.dispatch('billingapprovingofficers/updateApprovingOfficer', {
						data: {
							id,
							user_id,
							currency_id,
							min_amount,
							max_amount,
							level,
							edit_by
						},
						index,
						users
					});
				} else {
					await this.$store.dispatch('billingapprovingofficers/saveApprovingOfficer', {
						data: {
							user_id,
							currency_id,
							min_amount,
							max_amount,
							level,
							edit_by
						},
						users
					});
				}
				setTimeout(() => {
					this.close();
				}, 3000);
			}
		},

		displayFullname() {
			const id = this.editedItem.user_id;
			const approver = this.users.data.filter(user => user.id == id);

			if (approver.length == 1) {
				let {user_first_name, user_last_name} = approver[0];

				this.editedItem.approver_name = `${user_first_name} ${user_last_name}`;
			}
		},

		displayAmount() {
			const id = this.editedItem.level;
			const level = this.approvinglevels.filter(level => level.id == id);

			if (level.length == 1) {
				let {min_amount, max_amount} = level[0];

				this.editedItem.min_amount = min_amount;
				this.editedItem.max_amount = max_amount;
			}
		}
	}
};
</script>